<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <property-address
          :propertyId="reservation.propertyId"
          class="title is-6 mb-1"
        />
      </div>
    </div>
    <div class="card-content">
      <div v-if="reservation.primaryGuest">
        {{ reservation.primaryGuest.nameFirst }}
        {{ reservation.primaryGuest.nameLast }}
      </div>
      <div>Status: {{ reservation.status }}</div>
      <div>
        Arrives: {{ reservation.dateArrivalUnix | moment("MMM Do YYYY") }}
      </div>
      <div>
        Departs: {{ reservation.dateDepartureUnix | moment("MMM Do YYYY") }}
      </div>
      <div>Code: {{ reservation.code }}</div>
    </div>
    <div class="card-footer">
      <router-link
        class="card-footer-item"
        :to="{ name: 'ViewReservation', params: { id: reservation.id } }"
        >View</router-link
      >
      <router-link
        class="card-footer-item"
        :to="{ name: 'EditReservation', params: { id: reservation.id } }"
        >Edit</router-link
      >
    </div>
  </div>
</template>

<script>
import propertyAddress from "../property/address.vue";

export default {
  props: ["reservation"],
  components: { propertyAddress }
};
</script>

<style lang="scss" scoped></style>
