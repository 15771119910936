<template>
  <div>
    <h3>Add User</h3>
    First Name, Last Name, Email Address, Role [Property Mananger, Owner,
    Approved Person], Notification Type [None, SMS, Email]

    <form @submit.prevent="handleSubmit">
      <div>
        <label for="">First Name</label>
        <input v-model="user.nameFirst" type="text" />
      </div>
      <div>
        <label for="">Last Name</label>
        <input v-model="user.nameLast" type="text" />
      </div>
      <div>
        <label for="">Email</label
        ><input v-model="user.email" type="email" name="" id="" />
      </div>
      <div>
        <label for="">Phone</label><input v-model="user.phone" type="text" />
      </div>
      <div>
        <label for="">Role</label>
        <select name="" id="" v-model="user.role">
          <option value="propertyManager">Property Mananger</option>
          <option value="owner">Owner</option>
          <option value="approvedPerson">Approved Person</option>
        </select>
      </div>
      <div>
        <label for="">Send Notification</label>
        <select name="" id="" v-model="user.notify">
          <option value="no">No</option>
          <option value="email">By Email</option>
          <option value="text">By Text</option>
        </select>
      </div>
      <div>
        <button type="submit">Add</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
