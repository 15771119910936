<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">Edit Reservation</div>
    </div>
    <div class="card-content">
      <form @submit.prevent="handleSubmit">
        <div class="field">
          <label for="" class="label">Property</label>
          <div class="control">
            <input
              class="input"
              type="text"
              disabled
              :value="propertyAddress"
            />
          </div>
        </div>
        <div></div>
        <div class="field">
          <label for="" class="label">Arrival Date and Time</label>
          <b-datetimepicker
            v-model="localReservation.dateArrival"
            placeholder="Click to select..."
            icon="calendar-today"
            horizontal-time-picker
          >
          </b-datetimepicker>
        </div>
        <div class="field">
          <label for="" class="label">Departure Date and Time</label>

          <b-datetimepicker
            v-model="localReservation.dateDeparture"
            placeholder="Click to select..."
            icon="calendar-today"
            horizontal-time-picker
          >
          </b-datetimepicker>
        </div>
        <div>
          <button class="button is-success is-fullwidth" type="submit">
            Add
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      localReservation: {
        dateArrival: null,
        dateDeparture: null
      }
    };
  },
  methods: {
    ...mapActions({
      getProperty: "property/getItem",
      getReservation: "reservation/getItem",
      getProperties: "property/getCollection",
      updateReservation: "reservation/updateItem"
    }),
    async handleSubmit() {
      await this.updateReservation({
        dateArrival: this.localReservation.dateArrival,
        dateDeparture: this.localReservation.dateDeparture,
        id: this.reservation.id
      });
      alert("created reservation, make first guest or skip");
      this.$router.push({
        name: "ViewReservation",
        params: {
          id: this.reservation.id
        }
      });
    }
  },
  computed: {
    ...mapState({
      property: (state) => state.property.item,
      reservation: (state) => state.reservation.item
    }),
    propertyAddress() {
      return `${this.property.houseNumber} ${this.property.streetName}`;
    }
  },
  async mounted() {
    await this.getReservation(this.$route.params.id);
    await this.getProperty(this.reservation.propertyId);
    this.localReservation.dateArrival = new Date(
      this.reservation.dateArrivalUnix * 1000
    );
    this.localReservation.dateDeparture = new Date(
      this.reservation.dateDepartureUnix * 1000
    );
  }
};
</script>

<style lang="scss" scoped></style>
