<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        {{ guest.nameFirst }} {{ guest.nameLast }}
      </div>
    </div>
    <div class="card-footer">
      <a @click="removeGuest" class="card-footer-item">
        <small>Send Pass</small>
      </a>
      <router-link
        class="card-footer-item"
        :to="{ name: 'EditGuest', params: { id: guest.id } }"
        >Edit</router-link
      >
      <a @click="removeGuest" class="card-footer-item">
        Remove
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["guest"],
  ...mapState({
    reservation: (state) => state.reservation.item
  }),
  methods: {
    removeGuest() {}
  }
};
</script>

<style lang="scss" scoped></style>
