<template>
  <div class="" v-if="!loading">
    <div class="">
      <div class="card mb-5">
        <div class="card-header">
          <h3 v-if="property" class="card-header-title">
            {{ property.houseNumber }}
            {{ property.streetName }}
          </h3>
        </div>
        <div class="card-content">
          Other information can go here.
        </div>
        <div class="card-footer">
          <router-link
            class="card-footer-item"
            v-if="property"
            :to="{
              name: 'AddReservation',
              params: { propertyId: property.id }
            }"
          >
            Add Reservation
          </router-link>
          <router-link
            class="card-footer-item"
            v-if="property"
            :to="{
              name: 'AddVisitor',
              params: { propertyId: property.id }
            }"
          >
            Add Visitor
          </router-link>
          <router-link
            class="card-footer-item"
            v-if="property"
            :to="{
              name: 'AddVendor',
              params: { propertyId: property.id }
            }"
          >
            Add Vendor
          </router-link>
          <router-link
            class="card-footer-item"
            v-if="property"
            :to="{
              name: 'AddUser',
              params: { propertyId: property.id }
            }"
          >
            Add Manager
          </router-link>
        </div>
      </div>

      <h3 class="title is-size-5 mb-0">Access</h3>

      <b-tabs v-model="accessTabs">
        <b-tab-item label="Visitors">
          <div v-for="visitor in visitors" :key="visitor.id" class="columns">
            <div class="column is-one-third">
              <visitor-card :visitor="visitor" />
            </div>
          </div>
        </b-tab-item>

        <b-tab-item label="Vendors">
          <div v-for="vendor in vendors" :key="vendor.id" class="columns">
            <div class="column is-one-third">
              <vendor-card :vendor="vendor" />
            </div>
          </div>
        </b-tab-item>

        <b-tab-item label="Managers">
          <router-link
            v-if="property"
            :to="{ name: 'AddUser', params: { propertyId: property.id } }"
            >Add User</router-link
          >

          <p>
            A list of users - e.g spouse, property managers, kids, etc, whomever
            you want to grant access to the account.
          </p>
        </b-tab-item>
      </b-tabs>

      <h3 class="title is-size-5 mb-0">Reservations</h3>
      <b-tabs v-model="activeTab">
        <b-tab-item label="Current">
          <!-- <current-reservations-widget :properties="properties" /> -->
          <currentReservationsCard :property="property" />
        </b-tab-item>

        <b-tab-item label="Upcoming">
          <upcomingReservationsCard :property="property" />
        </b-tab-item>

        <b-tab-item label="Pending">
          <pendingReservationsCard :property="property" />
        </b-tab-item>
        <b-tab-item label="Canceled">
          <canceledReservationsCard :property="property" />
        </b-tab-item>
      </b-tabs>
    </div>
  </div>
</template>

<script>
// import reservationCard from "@/components/reservation/card.vue";
import visitorCard from "@/components/visitor/card.vue";
import vendorCard from "@/components/vendor/card.vue";
// import currentReservationsWidget from "../../components/reservation/current-widget.vue";
// Views
import currentReservationsCard from "./PropertyViewTabs/current.vue";
import upcomingReservationsCard from "./PropertyViewTabs/upcoming.vue";
import pendingReservationsCard from "./PropertyViewTabs/pending.vue";
import canceledReservationsCard from "./PropertyViewTabs/canceled.vue";
// import upcomingReservationsWidget from "../../components/reservation/upcoming-widget.vue";

import { mapState, mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      loading: true,
      activeTab: 0,
      accessTabs: 0
      // currentTabComponent: currentReservationsWidget
    };
  },

  components: {
    visitorCard,
    vendorCard,
    // currentReservationsWidget,
    currentReservationsCard,
    upcomingReservationsCard,
    canceledReservationsCard,
    pendingReservationsCard
    // upcomingReservationsWidget
  },
  computed: {
    ...mapState({
      reservations: (state) => state.reservation.collection,
      visitors: (state) => state.visitor.collection,
      vendors: (state) => state.vendor.collection,
      property: (state) => state.property.item,
      properties: (state) => state.property.collection
    }),
    ...mapGetters({
      propertiesGetter: "property/getProperties"
    })
  },
  methods: {
    ...mapActions({
      activateLoading: "loading/activateLoading",
      deactivateLoading: "loading/deactivateLoading",
      getReservations: "reservation/getCollection",
      getVisitors: "visitor/getCollection",
      getVendors: "vendor/getCollection",
      // getGuests: "guest/getCollection",
      getProperty: "property/getItem",
      getProperties: "property/getCollection"
    })
  },
  async mounted() {
    this.loading = true;
    this.activateLoading();
    await this.getProperty(this.$route.params.id);
    await this.getProperties();

    await this.getReservations([{ id: this.$route.params.id }]);
    await this.getVisitors(this.$route.params.id);
    await this.getVendors(this.$route.params.id);
    this.loading = false;
    this.deactivateLoading();
  }
};
</script>

<style lang="scss" scoped></style>
