<template>
  <section class="section">
    <div class="container has-text-centered">
      <div class="columns is-centered">
        <div class="column is-5 is-4-desktop">
          <form @submit.prevent="handleSubmit">
            <div class="field">
              <div class="control">
                <input
                  class="input"
                  type="email"
                  placeholder="Email"
                  v-model="form.email"
                />
              </div>
            </div>
            <div class="field">
              <div class="control">
                <input
                  class="input"
                  type="password"
                  placeholder="Password"
                  v-model="form.password"
                />
              </div>
            </div>
            <div class="field">
              <button class="button is-primary is-fullwidth" type="submit">
                Log In
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import { version } from "../../../package.json";

export default {
  name: "Login",
  computed: {
    version() {
      return version || 0;
    }
  },
  data() {
    return {
      form: {
        email: "",
        password: ""
      }
    };
  },
  methods: {
    ...mapActions({
      signInAction: "user/signInAction",
      authAction: "user/authAction"
    }),
    async handleSubmit() {
      try {
        const { email, password } = this.form;
        await this.signInAction({ email, password });
        this.$router.push({ name: "Dashboard" });
      } catch (error) {
        console.warn("error", error);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
