<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        Add Visitor
      </div>
    </div>
    <div class="card-content">
      <form @submit.prevent="handleSubmit">
        <div class="field">
          <label class="label" for="">First Name</label>
          <div class="control">
            <input class="input" v-model="visitor.nameFirst" type="text" />
          </div>
        </div>
        <div class="field">
          <label class="label" for="">Last Name</label>
          <div class="control">
            <input class="input" v-model="visitor.nameLast" type="text" />
          </div>
        </div>
        <div class="field">
          <label class="label" for="">Email</label>
          <div class="control">
            <input
              class="input"
              v-model="visitor.email"
              type="email"
              name=""
              id=""
            />
          </div>
        </div>
        <div class="field">
          <label class="label" for="">Phone</label>
          <div class="control"></div>
          <input class="input" v-model="visitor.phone" type="text" />
        </div>
        <div class="field">
          <label class="label" for="">Type</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select name="" id="" v-model="visitor.type">
                <option value="once">One Time</option>
                <option value="reoccurring">Reoccuring</option>
              </select>
            </div>
          </div>

          <div v-if="visitor.type === 'reoccurring'">
            <div>
              <label class="label" for="">Allow Access On</label>
              <b-datetimepicker
                v-model="visitor.dateArrival"
                placeholder="Click to select..."
                icon="calendar-today"
                horizontal-time-picker
              >
              </b-datetimepicker>
            </div>
            <div>
              <label class="label" for="">Expire Access On</label>
              <b-datetimepicker
                v-model="visitor.dateDeparture"
                placeholder="Click to select..."
                icon="calendar-today"
                horizontal-time-picker
              >
              </b-datetimepicker>
            </div>
          </div>
        </div>
        <div class="field">
          <label class="label" for="">Send Notification</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select name="" id="" v-model="visitor.notify">
                <option value="no">No</option>
                <option value="email">By Email</option>
                <option value="text">By Text</option>
              </select>
            </div>
          </div>
        </div>
        <div>
          <button class="button is-success is-fullwidth" type="submit">
            Add
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      visitor: {}
    };
  },
  computed: {
    ...mapState({
      property: (state) => state.property.item
    })
  },
  methods: {
    ...mapActions({
      getProperty: "property/getItem",
      addVisitor: "visitor/createItem"
    }),
    async handleSubmit() {
      const { property, visitor } = this;
      await this.addVisitor({ property, visitor });
      alert("Added Visitor to Property");
      this.$router.push({
        name: "ViewProperty",
        params: { id: this.property.id }
      });
    }
  },
  async mounted() {
    if (
      !this.property ||
      !this.property.id ||
      this.property.id !== this.$route.params.propertyId
    ) {
      await this.getProperty(this.$route.params.propertyId);
    }
  }
};
</script>

<style lang="scss" scoped></style>
