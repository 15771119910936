import Vue from "vue";
import Vuex from "vuex";

import { user } from "./user.store.js";
// import { alert } from "./alert.store.js";
import { loading } from "./loading.store.js";
// import { modal } from "./modal.store.js"
import { property } from "./property.store";
import { reservation } from "./reservation.store";
import { guest } from "./guest.store";
import { visitor } from "./visitor.store.js";
import { vendor } from "./vendor.store.js";
import { account } from "./account.store";
Vue.use(Vuex);
export const store = new Vuex.Store({
  modules: {
    // alert,
    loading,
    // modal,
    user,
    property,
    reservation,
    guest,
    visitor,
    vendor,
    account
  }
});
