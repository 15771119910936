<template>
  <div class="card">
    <div class="card-header is-block">
      <div class="columns" v-if="property && reservation">
        <div class="column">
          <div class="card-header-title is-size-4">
            Add A Guest
          </div>
        </div>
        <div class="column has-text-right mr-4">
          <h5 class="">
            {{ property.houseNumber }} {{ property.streetName }}<br />
            {{ reservation.dateArrivalUnix | moment("ddd, MMM Do YYYY") }} to
            {{ reservation.dateDepartureUnix | moment("ddd, MMM Do YYYY") }}
          </h5>
        </div>
      </div>
    </div>
    <div class="card-content">
      <form @submit.prevent="handleSubmit">
        <div class="field">
          <label class="label">First Name</label>
          <div class="control">
            <input class="input" v-model="guest.nameFirst" type="text" />
          </div>
        </div>
        <div class="field">
          <label class="label">Last Name</label>
          <div class="control">
            <input class="input" v-model="guest.nameLast" type="text" />
          </div>
        </div>
        <div class="field">
          <label class="label">Email</label
          ><input
            class="input"
            v-model="guest.email"
            type="email"
            name=""
            id=""
          />
        </div>
        <div class="field">
          <label class="label">Phone</label>
          <div class="control">
            <input class="input" v-model="guest.phone" type="text" />
          </div>
        </div>
        <div class="field">
          <label class="label">Send Notification</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select name="" id="" v-model="guest.notify">
                <option value="no">No</option>
                <option value="email">By Email</option>
                <option value="text">By Text</option>
              </select>
            </div>
          </div>
        </div>
        <b-field v-if="!reservation.primaryGuest" class="mt-4">
          <b-checkbox v-model="makePrimary" size="is-medium"
            >Make Primary Guest</b-checkbox
          >
        </b-field>

        <div>
          <button class="button is-success is-fullwidth" type="submit">
            Add
          </button>
        </div>
        <div class="has-text-centered mt-4">
          <button @click="handleSkip" class="button is-text ">
            Skip
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      makePrimary: false,
      guest: {}
    };
  },
  computed: {
    ...mapState({
      property: (state) => state.property.item,
      reservation: (state) => state.reservation.item
    })
  },
  methods: {
    ...mapActions({
      getProperty: "property/getItem",
      getReservation: "reservation/getItem",
      addGuest: "guest/createItem"
    }),
    async handleSkip() {
      this.$router.push({
        name: "ViewReservation",
        params: { id: this.reservation.id }
      });
    },
    async handleSubmit() {
      await this.addGuest({
        reservation: { id: this.reservation.id, makePrimary: this.makePrimary },
        guest: {
          propertyId: this.property.id,
          ...this.guest
        }
      });
      alert("Added Guest to Reservation");
      this.$router.push({
        name: "ViewReservation",
        params: { id: this.reservation.id }
      });
    }
  },
  async mounted() {
    if (
      !this.property ||
      !this.property.id ||
      this.property.id !== this.$route.params.propertyId
    ) {
      await this.getProperty(this.$route.params.propertyId);
    }
    if (
      !this.reservation ||
      !this.reservation.id ||
      this.reservation.id !== this.$route.params.reservationId
    ) {
      await this.getReservation(this.$route.params.reservationId);
    }
  }
};
</script>

<style lang="scss" scoped></style>
