<template>
  <div class="card">
    <div class="card-header is-block">
      <div class="columns">
        <div class="column">
          <div class="card-header-title is-size-4 mb-0 pb-0">
            Edit Guest
          </div>
          <a class="is-size-7" @click="$router.go(-1)">back</a>
        </div>
        <div class="column has-text-right mr-4">
          <h5 class="" v-show="guest.property">
            {{ guest.property.houseNumber }} {{ guest.property.streetName
            }}<br />
            {{ guest.reservation.dateArrivalUnix | moment("ddd, MMM Do YYYY") }}
            to
            {{
              guest.reservation.dateDepartureUnix | moment("ddd, MMM Do YYYY")
            }}
          </h5>
        </div>
      </div>
    </div>
    <div class="card-content">
      <form @submit.prevent="handleSubmit">
        <div class="field">
          <label class="label">First Name</label>
          <div class="control">
            <input class="input" v-model="localGuest.nameFirst" type="text" />
          </div>
        </div>
        <div class="field">
          <label class="label">Last Name</label>
          <div class="control">
            <input class="input" v-model="localGuest.nameLast" type="text" />
          </div>
        </div>
        <div class="field">
          <label class="label">Email</label
          ><input
            class="input"
            v-model="localGuest.email"
            type="email"
            name=""
            id=""
          />
        </div>
        <div class="field">
          <label class="label">Phone</label>
          <div class="control">
            <input class="input" v-model="localGuest.phone" type="text" />
          </div>
        </div>
        <div class="field">
          <label class="label">Send Notification</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select name="" id="" v-model="localGuest.notify">
                <option value="no">No</option>
                <option value="email">By Email</option>
                <option value="text">By Text</option>
              </select>
            </div>
          </div>
        </div>
        <b-field v-if="!reservation.primarylocalGuest" class="mt-4">
          <b-checkbox v-model="makePrimary" size="is-medium"
            >Primary Guest</b-checkbox
          >
        </b-field>

        <div>
          <button class="button is-success is-fullwidth" type="submit">
            Update
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      makePrimary: false,
      localGuest: {
        nameFirst: "",
        nameLast: "",
        email: "",
        phone: "",
        notify: ""
      }
    };
  },
  computed: {
    ...mapState({
      guest: (state) => state.guest.item,
      property: (state) => state.property.item,
      reservation: (state) => state.reservation.item
    })
  },
  methods: {
    ...mapActions({
      getGuest: "guest/getItem",
      getProperty: "property/getItem",
      getReservation: "reservation/getItem",
      updateGuest: "guest/updateItem"
    }),
    async handleSubmit() {
      await this.updateGuest({
        reservation: { id: this.reservation.id, makePrimary: this.makePrimary },
        guest: {
          ...this.localGuest
        },
        guestId: this.guest.id
      });
      alert("Updated Guest to Reservation");
      this.$router.push({
        name: "ViewReservation",
        params: { id: this.reservation.id }
      });
    }
  },
  async mounted() {
    let guestId = this.$route.params.id;
    // alert(guestId);
    await this.getGuest(guestId);

    if (this.guest) {
      for (let variable in this.localGuest) {
        this.localGuest[variable] = this.guest[variable];
      }
      if (
        this.guest.reservation.primaryGuest &&
        this.guest.reservation.primaryGuest === this.guest.id
      ) {
        this.makePrimary = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
