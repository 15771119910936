<template>
  <div v-if="loaded">
    <div class="columns is-multiline">
      <div v-if="reservations.length === 0" class="column">
        No reservations to display.
      </div>
      <div
        v-for="reservation in reservations"
        :key="reservation.id"
        class="column is-one-third mb-4"
      >
        <reservation-card :reservation="reservation" />
      </div>
    </div>
  </div>
</template>

<script>
import reservationCard from "../../../components/reservation/card.vue";
import { mapActions, mapState } from "vuex";
export default {
  props: ["property"],
  components: { reservationCard },
  data() {
    return {
      loaded: false
    };
  },
  computed: {
    ...mapState({
      reservations: (state) => state.reservation.pending
    })
  },
  methods: {
    ...mapActions({
      getReservations: "reservation/getCollectionByStatus"
    })
  },
  async mounted() {
    this.loaded = false;
    if (this.property) {
      await this.getReservations({
        properties: [{ id: this.property.id }],
        status: "pending"
      });
      this.loaded = false;
    } else {
      await this.getReservations({
        status: "pending"
      });
      setTimeout(() => {
        this.loaded = true;
      }, 1000);
    }
  }
};
</script>

<style lang="scss" scoped></style>
