<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        {{ visitor.nameFirst }} {{ visitor.nameLast }}
      </div>
    </div>
    <div class="card-content">
      <div>
        Type: <span class="is-capitalized">{{ visitor.type }}</span>
      </div>
      <div>Notify By: {{ visitor.notify }}</div>
      <div>Phone: {{ visitor.phone }}</div>
      <div>Email: {{ visitor.email }}</div>
    </div>
    <div class="card-footer">
      <a class="card-footer-item" @click="sendPass">
        <small> Send Pass</small>
      </a>
      <router-link
        class="card-footer-item"
        :to="{ name: 'EditVisitor', params: { id: visitor.id } }"
        >Edit</router-link
      >
      <a @click="removeVisitor" class="card-footer-item">
        Remove
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["visitor"],
  ...mapState({
    reservation: (state) => state.reservation.item
  }),
  methods: {
    removeVisitor() {},
    sendPass() {}
  }
};
</script>

<style lang="scss" scoped></style>
