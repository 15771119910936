import { firebase } from "../firebaseConfig";

const state = {
  item: null,
  collection: []
};

const mutations = {
  SET_ITEM(state, payload) {
    state.item = payload;
  },
  CLEAR_ITEM(state) {
    state.item = null;
  },
  SET_COLLECTION(state, payload) {
    state.collection = payload;
  },
  ADD_ITEM_TO_COLLECTION(state, payload) {
    state.collection.unshift(payload);
  },
  REMOTE_ITEM_FROM_COLLECTION(state, payload) {
    const i = state.collection.map((item) => item.id).indexOf(payload.id);
    state.collection.splice(i, 1);
  },
  CLEAR_COLLECTION(state) {
    state.collection = [];
  }
};

const actions = {
  async createItem({ commit }, item) {
    try {
      const { vendor, property } = item;

      let newVisitor = await firebase
        .firestore()
        .collection("vendors")
        .add({ ...vendor, propertyId: property.id });

      const tempItem = {
        id: newVisitor.id,
        ...vendor
      };
      commit("ADD_ITEM_TO_COLLECTION", tempItem);
      commit("SET_ITEM", tempItem);
      return Promise.resolve();
    } catch (error) {
      console.error("createItem error", error);
      return Promise.reject();
    }
  },
  // async deleteItem({ commit }, item) {
  //   try {
  //     commit("REMOTE_ITEM_FROM_COLLECTION");
  //     return Promise.resolve();
  //   } catch (error) {
  //     console.error("createDelete error", error);
  //     return Promise.reject();
  //   }
  // },
  // async getItem({ commit }, item) {
  //   try {
  //     commit("SET_ITEM");
  //     return Promise.resolve();
  //   } catch (error) {
  //     console.error("getItem error", error);
  //     return Promise.reject();
  //   }
  // },
  async getCollection({ commit }, propertyId) {
    commit("CLEAR_COLLECTION");
    try {
      let vendorQuery = await firebase
        .firestore()
        .collection("vendors")
        .where("propertyId", "==", propertyId)
        .get();

      vendorQuery.docs.forEach((vendor) => {
        let docObj = {
          id: vendor.id,
          ...vendor.data()
        };
        commit("ADD_ITEM_TO_COLLECTION", docObj);
      });
      return Promise.resolve();
    } catch (error) {
      console.error("getItems error", error);
      return Promise.reject();
    }
  }
};

const getters = {};
export const vendor = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
