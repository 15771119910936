<template>
  <div>
    <div>
      <router-link class="button mr-2" :to="{ name: 'AddReservation' }">
        Add Reservation
      </router-link>
    </div>
    <div class="columns" v-if="propertiesGetter.length > 0">
      <div class="column">
        <h3 class="subtitle mb-2">Current Reservations</h3>
        <current-reservations-widget :properties="propertiesGetter" />
      </div>
      <div class="column">
        <h3 class="subtitle mb-2">Upcoming Reservations</h3>
        <upcoming-reservations-widget :properties="propertiesGetter" />
      </div>
      <div class="column">
        <h3 class="subtitle mb-2">Pending Reservations</h3>
        <pending-reservations-widget :properties="propertiesGetter" />
      </div>
    </div>
  </div>
</template>

<script>
import pendingReservationsWidget from "@/components/reservation/pending-widget.vue";
// import reservationListItem from "@/components/reservation/list-item.vue";
import { mapActions, mapState, mapGetters } from "vuex";
import upcomingReservationsWidget from "../../components/reservation/upcoming-widget.vue";
import currentReservationsWidget from "../../components/reservation/current-widget.vue";
export default {
  components: {
    upcomingReservationsWidget,
    currentReservationsWidget,
    pendingReservationsWidget
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      propertiesGetter: "property/getProperties"
    }),
    ...mapState({
      properties: (state) => state.property.collection
    })
  },
  methods: {
    ...mapActions({
      getProperties: "property/getCollection",
      clearAllReservationCollections: "reservation/clearAllCollections"
    })
  },
  async mounted() {
    await this.clearAllReservationCollections();
    await this.getProperties();
  }
};
</script>

<style lang="scss" scoped></style>
