var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('div',{},[_c('div',{},[_c('div',{staticClass:"card mb-5"},[_c('div',{staticClass:"card-header"},[(_vm.property)?_c('h3',{staticClass:"card-header-title"},[_vm._v(" "+_vm._s(_vm.property.houseNumber)+" "+_vm._s(_vm.property.streetName)+" ")]):_vm._e()]),_c('div',{staticClass:"card-content"},[_vm._v(" Other information can go here. ")]),_c('div',{staticClass:"card-footer"},[(_vm.property)?_c('router-link',{staticClass:"card-footer-item",attrs:{"to":{
            name: 'AddReservation',
            params: { propertyId: _vm.property.id }
          }}},[_vm._v(" Add Reservation ")]):_vm._e(),(_vm.property)?_c('router-link',{staticClass:"card-footer-item",attrs:{"to":{
            name: 'AddVisitor',
            params: { propertyId: _vm.property.id }
          }}},[_vm._v(" Add Visitor ")]):_vm._e(),(_vm.property)?_c('router-link',{staticClass:"card-footer-item",attrs:{"to":{
            name: 'AddVendor',
            params: { propertyId: _vm.property.id }
          }}},[_vm._v(" Add Vendor ")]):_vm._e(),(_vm.property)?_c('router-link',{staticClass:"card-footer-item",attrs:{"to":{
            name: 'AddUser',
            params: { propertyId: _vm.property.id }
          }}},[_vm._v(" Add Manager ")]):_vm._e()],1)]),_c('h3',{staticClass:"title is-size-5 mb-0"},[_vm._v("Access")]),_c('b-tabs',{model:{value:(_vm.accessTabs),callback:function ($$v) {_vm.accessTabs=$$v},expression:"accessTabs"}},[_c('b-tab-item',{attrs:{"label":"Visitors"}},_vm._l((_vm.visitors),function(visitor){return _c('div',{key:visitor.id,staticClass:"columns"},[_c('div',{staticClass:"column is-one-third"},[_c('visitor-card',{attrs:{"visitor":visitor}})],1)])}),0),_c('b-tab-item',{attrs:{"label":"Vendors"}},_vm._l((_vm.vendors),function(vendor){return _c('div',{key:vendor.id,staticClass:"columns"},[_c('div',{staticClass:"column is-one-third"},[_c('vendor-card',{attrs:{"vendor":vendor}})],1)])}),0),_c('b-tab-item',{attrs:{"label":"Managers"}},[(_vm.property)?_c('router-link',{attrs:{"to":{ name: 'AddUser', params: { propertyId: _vm.property.id } }}},[_vm._v("Add User")]):_vm._e(),_c('p',[_vm._v(" A list of users - e.g spouse, property managers, kids, etc, whomever you want to grant access to the account. ")])],1)],1),_c('h3',{staticClass:"title is-size-5 mb-0"},[_vm._v("Reservations")]),_c('b-tabs',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('b-tab-item',{attrs:{"label":"Current"}},[_c('currentReservationsCard',{attrs:{"property":_vm.property}})],1),_c('b-tab-item',{attrs:{"label":"Upcoming"}},[_c('upcomingReservationsCard',{attrs:{"property":_vm.property}})],1),_c('b-tab-item',{attrs:{"label":"Pending"}},[_c('pendingReservationsCard',{attrs:{"property":_vm.property}})],1),_c('b-tab-item',{attrs:{"label":"Canceled"}},[_c('canceledReservationsCard',{attrs:{"property":_vm.property}})],1)],1)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }