<template>
  <div>
    <div class="card mb-4">
      <div class="card-header">
        <div class="card-header-title is-size-4">
          Reservations:
          <span class="is-capitalized ml-2">{{ $route.params.type }}</span>
        </div>
      </div>
    </div>
    <component v-bind:is="showReservationsOfType"></component>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import current from "./PropertyViewTabs/current.vue";
import upcoming from "./PropertyViewTabs/upcoming.vue";
import pending from "./PropertyViewTabs/pending.vue";
import canceled from "./PropertyViewTabs/canceled.vue";
export default {
  components: {
    current,
    upcoming,
    canceled,
    pending
  },
  data() {
    return {
      showReservationsOfType: ""
    };
  },
  computed: {
    ...mapGetters({
      propertiesGetter: "property/getProperties"
    }),
    ...mapState({
      properties: (state) => state.property.collection
    })
  },
  methods: {
    ...mapActions({
      getProperties: "property/getCollection",
      clearReservations: "reservation/clearCollectionByStatus",
      activateLoading: "loading/activateLoading",
      deactivateLoading: "loading/deactivateLoading"
    })
  },
  async mounted() {
    let statusType = this.$route.params.type;
    if (statusType) {
      this.showReservationsOfType = statusType;
    }
  },
  async beforeRouteUpdate(to, from, next) {
    this.activateLoading();
    await this.clearReservations(to.params.type); // clear out the one we're on
    await this.clearReservations(from.params.type); // clear out the one we were on
    this.showReservationsOfType = to.params.type;
    this.deactivateLoading();
    next();
  }
};
</script>

<style lang="scss" scoped></style>
