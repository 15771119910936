import { firebase } from "../firebaseConfig";

const state = {
  item: null,
  collection: []
};

const mutations = {
  SET_ITEM(state, payload) {
    state.item = payload;
  },
  CLEAR_ITEM(state) {
    state.item = null;
  },
  SET_COLLECTION(state, payload) {
    state.collection = payload;
  },
  ADD_ITEM_TO_COLLECTION(state, payload) {
    state.collection.unshift(payload);
  },
  REMOTE_ITEM_FROM_COLLECTION(state, payload) {
    const i = state.collection.map((item) => item.id).indexOf(payload.id);
    state.collection.splice(i, 1);
  },
  CLEAR_COLLECTION(state) {
    state.collection = [];
  }
};

const actions = {
  async createItem({ commit }, item) {
    try {
      const { guest, reservation } = item;

      let newGuest = await firebase
        .firestore()
        .collection("guests")
        .add({ ...guest, reservationId: reservation.id });

      if (reservation.makePrimary) {
        await firebase
          .firestore()
          .collection("reservations")
          .doc(reservation.id)
          .update({ primaryGuest: newGuest.id });
      }

      commit("ADD_ITEM_TO_COLLECTION");
      commit("SET_ITEM");
      return Promise.resolve();
    } catch (error) {
      console.error("createItem error", error);
      return Promise.reject();
    }
  },
  async updateItem({ commit }, item) {
    try {
      console.log(item);
      const { guest, reservation, guestId } = item;

      await firebase
        .firestore()
        .collection("guests")
        .doc(guestId)
        .update({ ...guest });

      if (reservation.makePrimary) {
        await firebase
          .firestore()
          .collection("reservations")
          .doc(reservation.id)
          .update({ primaryGuest: guestId });
      }
      commit("REMOVE_ITEM_FROM_COLLECTION", {
        id: guestId
      });
      commit("ADD_ITEM_TO_COLLECTION", {
        id: guestId,
        ...guest
      });
      commit("SET_ITEM", { id: guestId, ...guest });
      return Promise.resolve();
    } catch (error) {
      console.error("createItem error", error);
      return Promise.reject();
    }
  },
  // async deleteItem({ commit }, item) {
  //   try {
  //     commit("REMOTE_ITEM_FROM_COLLECTION");
  //     return Promise.resolve();
  //   } catch (error) {
  //     console.error("createDelete error", error);
  //     return Promise.reject();
  //   }
  // },
  // async getItem({ commit }, item) {
  //   try {
  //     commit("SET_ITEM");
  //     return Promise.resolve();
  //   } catch (error) {
  //     console.error("getItem error", error);
  //     return Promise.reject();
  //   }
  // },
  async getItem({ commit }, guestId) {
    commit("CLEAR_ITEM");
    try {
      // Get Guests
      let guest = await firebase
        .firestore()
        .collection("guests")
        .doc(guestId)
        .get();

      let reservation = await firebase
        .firestore()
        .collection("reservations")
        .doc(guest.data().reservationId)
        .get();

      let property = await firebase
        .firestore()
        .collection("properties")
        .doc(reservation.data().propertyId)
        .get();

      commit("SET_ITEM", {
        id: guest.id,
        ...guest.data(),
        reservation: {
          id: reservation.id,
          ...reservation.data()
        },
        property: {
          id: property.id,
          ...property.data()
        }
      });
      return Promise.resolve();
    } catch (error) {
      console.error("getItems error", error);
      return Promise.reject();
    }
  },
  async getCollection({ commit }, reservationId) {
    commit("CLEAR_COLLECTION");
    try {
      // Get Guests
      let guestsQuery = await firebase
        .firestore()
        .collection("guests")
        .where("reservationId", "==", reservationId)
        .get();

      guestsQuery.docs.forEach((guest) => {
        console.log("guest", guest);
        let docObj = {
          id: guest.id,
          ...guest.data()
        };
        commit("ADD_ITEM_TO_COLLECTION", docObj);
      });
      return Promise.resolve();
    } catch (error) {
      console.error("getItems error", error);
      return Promise.reject();
    }
  }
};

const getters = {};
export const guest = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
