<template>
  <div class="card" v-if="loaded">
    <div class="card-header">
      <div class="card-header-title">
        Edit Visitor
      </div>
      <a
        class="is-pulled-right button is-small mt-2 mr-3"
        @click="$router.go(-1)"
        >back</a
      >
    </div>
    <div class="card-content">
      <form @submit.prevent="handleSubmit">
        <div class="field">
          <label class="label" for="">First Name</label>
          <div class="control">
            <input class="input" v-model="localVisitor.nameFirst" type="text" />
          </div>
        </div>
        <div class="field">
          <label class="label" for="">Last Name</label>
          <div class="control">
            <input class="input" v-model="localVisitor.nameLast" type="text" />
          </div>
        </div>
        <div class="field">
          <label class="label" for="">Email</label>
          <div class="control">
            <input
              class="input"
              v-model="localVisitor.email"
              type="email"
              name=""
              id=""
            />
          </div>
        </div>
        <div class="field">
          <label class="label" for="">Phone</label>
          <div class="control"></div>
          <input class="input" v-model="localVisitor.phone" type="text" />
        </div>
        <div class="field">
          <label class="label" for="">Type</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select name="" id="" v-model="localVisitor.type">
                <option value="once">One Time</option>
                <option value="reoccurring">Reoccuring</option>
              </select>
            </div>
          </div>

          <div v-if="visitor.type === 'reoccurring'">
            <div>
              <label class="label" for="">Allow Access On</label>
              <b-datetimepicker
                v-model="localVisitor.dateArrival"
                placeholder="Click to select..."
                icon="calendar-today"
                horizontal-time-picker
              >
              </b-datetimepicker>
            </div>
            <div>
              <label class="label" for="">Expire Access On</label>
              <b-datetimepicker
                v-model="localVisitor.dateDeparture"
                placeholder="Click to select..."
                icon="calendar-today"
                horizontal-time-picker
              >
              </b-datetimepicker>
            </div>
          </div>
        </div>
        <div class="field" v-if="visitor.notify">
          <label class="label" for="">Send Notification</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select v-model="localVisitor.notify">
                <option
                  v-for="(option, index) in notifyOptions"
                  :value="option.value"
                  :key="index"
                  >{{ option.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div>
          <button class="button is-success is-fullwidth" type="submit">
            Update
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      loaded: false,
      localVisitor: {
        nameFirst: "",
        nameLast: "",
        email: "",
        phone: "",
        type: "",
        notify: ""
      },
      notifyOptions: [
        { name: "SMS", value: "sms" },
        { name: "Email", value: "email" },
        { name: "None", value: "none" }
      ]
    };
  },
  computed: {
    ...mapState({
      property: (state) => state.property.item,
      visitor: (state) => state.visitor.item
    })
  },
  methods: {
    ...mapActions({
      getProperty: "property/getItem",
      getVisitor: "visitor/getItem",
      updateVisitor: "visitor/updateItem"
    }),
    async handleSubmit() {
      await this.updateVisitor({ id: this.visitor.id, ...this.localVisitor });
      alert("Updated Visitor");
      this.$router.push({
        name: "ViewProperty",
        params: { id: this.property.id }
      });
    }
  },
  async mounted() {
    this.loaded = false;
    await this.getVisitor(this.$route.params.id);
    for (let variable in this.localVisitor) {
      this.localVisitor[variable] = this.visitor[variable];
    }
    this.loaded = true;
  }
};
</script>

<style lang="scss" scoped></style>
