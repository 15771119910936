<template>
  <div>
    <div
      v-for="reservation in upcomingReservations"
      :key="reservation.id"
      class="mb-3"
    >
      <reservation-card :reservation="reservation" />
    </div>
  </div>
</template>

<script>
import reservationCard from "../reservation/card.vue";

import { mapActions, mapState } from "vuex";
export default {
  props: ["properties"],
  components: {
    reservationCard
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      upcomingReservations: (state) => state.reservation.approved
    })
  },
  methods: {
    ...mapActions({
      getCollection: "reservation/getCollectionByStatus"
    })
  },
  async created() {
    await this.getCollection({
      properties: this.properties,
      status: "approved"
    });
  }
};
</script>

<style lang="scss" scoped></style>
