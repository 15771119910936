<template>
  <div>
    <!-- <div>
      <router-link class="button" :to="{ name: 'AddReservation' }">
        Add Reservation
      </router-link>
      <router-link class="button" :to="{ name: 'AddVisitor' }">
        Add Visitor
      </router-link>
      <router-link class="button" :to="{ name: 'AddVendor' }">
        Add Vendor
      </router-link>
    </div> -->
    <h3 class="subtitle is-3">Properties</h3>
    <div class="columns is-multiline">
      <div
        class="column is-one-third"
        v-for="property in properties"
        :key="property.id"
      >
        <property-card :property="property" />
      </div>
    </div>
  </div>
</template>

<script>
import propertyCard from "@/components/property/card.vue";
// import reservationListItem from "@/components/reservation/list-item.vue";
import { mapActions, mapState, mapGetters } from "vuex";
export default {
  components: {
    propertyCard
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      propertiesGetter: "property/getProperties"
    }),
    ...mapState({
      properties: (state) => state.property.collection
    })
  },
  methods: {
    ...mapActions({
      getProperties: "property/getCollection"
    })
  },
  async mounted() {
    await this.getProperties();
  }
};
</script>

<style lang="scss" scoped></style>
