<template>
  <div id="app">
    <div class="navbar-menu">
      <div class="navbar-brand pt-2 pl-3">
        <i class="fad fa-gem fa-2x pt-1" style="color:green;"></i>
        <h1 class="is-size-4 ml-3 has-text-weight-bold">Emerald Access</h1>
      </div>
      <div class="navbar-start">
        <!-- navbar items -->
      </div>

      <div class="navbar-end" v-if="isLoggedIn">
        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link">
            Reservations
          </a>

          <div class="navbar-dropdown">
            <router-link class="navbar-item" :to="{ name: 'Dashboard' }"
              >Dashboard</router-link
            >
            <router-link
              class="navbar-item"
              :to="{ name: 'ViewReservations', params: { type: 'current' } }"
              >Current</router-link
            >
            <router-link
              class="navbar-item"
              :to="{ name: 'ViewReservations', params: { type: 'upcoming' } }"
              >Upcoming</router-link
            >
            <router-link
              class="navbar-item"
              :to="{ name: 'ViewReservations', params: { type: 'pending' } }"
              >Pending</router-link
            >
            <router-link
              class="navbar-item"
              :to="{ name: 'ViewReservations', params: { type: 'canceled' } }"
              >Canceled</router-link
            >
          </div>
        </div>
      </div>

      <router-link class="navbar-item" :to="{ name: 'Properties' }"
        >Properties</router-link
      >
      <a href="" class="navbar-item" @click="handleLogOut"> Log Out </a>
    </div>
    <div class="container mt-3">
      <router-view />
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
// import Loading from "@/components/Loading";
// import Modal from "@/components/Modal";
export default {
  name: "App",
  components: {},
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.user.isLoggedIn
    })
  },
  methods: {
    ...mapActions({
      logout: "user/signOutAction"
    }),
    async handleLogOut() {
      await this.logout();
      this.$router.push({ path: "/" });
    }
  }
  // components: {
  //   Loading,
  //   Modal
  // }
};
</script>
<style lang="scss">
// body {
//   background-color: black;
//   color: white;
//   input {
//     background-color: grey;
//   }
// }
html {
  background-color: #f9f9f9;
}
</style>
