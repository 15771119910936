<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        {{ vendor.company }}
        <div>{{ vendor.nameFirst }} {{ vendor.nameLast }}</div>
      </div>
    </div>
    <div class="card-content">
      <div>
        Type: <span class="is-capitalized">{{ vendor.type }}</span>
      </div>
      <div>
        {{ vendor.dateArrival | moment("ddd, MMM Do YYYY @ h:mm A") }}
      </div>
      <div>
        {{ vendor.dateDeparture | moment("ddd, MMM Do YYYY @ h:mm A") }}
      </div>
    </div>
    <div class="card-footer">
      <router-link
        class="card-footer-item"
        :to="{ name: 'EditVendor', params: { id: vendor.id } }"
        >Edit</router-link
      >
      <a @click="removeVendor" class="card-footer-item">
        Remove
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: ["vendor"],

  methods: {
    removeVendor() {}
  }
};
</script>

<style lang="scss" scoped></style>
