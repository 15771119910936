<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">Add Reservation</div>
    </div>
    <div class="card-content">
      <form @submit.prevent="handleSubmit">
        <div class="field">
          <label for="" class="label">Property</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select
                v-model="reservation.propertyId"
                v-if="properties.length > 0"
              >
                <option
                  :value="property.id"
                  v-for="property in properties"
                  :key="property.id"
                >
                  {{ property.houseNumber }} {{ property.streetName }}
                </option>
                <!-- properties -->
              </select>
            </div>
          </div>
        </div>
        <div class="field">
          <label for="" class="label">Arrival Date and Time</label>

          <b-datetimepicker
            v-model="reservation.dateArrival"
            placeholder="Click to select..."
            icon="calendar-today"
            horizontal-time-picker
          >
          </b-datetimepicker>
        </div>
        <div class="field">
          <label for="" class="label">Departure Date and Time</label>

          <b-datetimepicker
            v-model="reservation.dateDeparture"
            placeholder="Click to select..."
            icon="calendar-today"
            horizontal-time-picker
          >
          </b-datetimepicker>
        </div>
        <div>
          <button class="button is-success is-fullwidth" type="submit">
            Add
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      reservation: {}
    };
  },
  methods: {
    ...mapActions({
      getProperties: "property/getCollection",
      createReservation: "reservation/createItem"
    }),
    async handleSubmit() {
      let newReservation = await this.createReservation(this.reservation);
      alert("created reservation, make first guest or skip");
      this.$router.push({
        name: "AddGuest",
        params: {
          propertyId: this.reservation.propertyId,
          reservationId: newReservation.id
        }
      });
    }
  },
  computed: {
    ...mapState({
      properties: (state) => state.property.collection
    })
  },
  async mounted() {
    if (this.properties.length === 0) {
      await this.getProperties();
    }
    if (this.$route.params.propertyId) {
      this.reservation.propertyId = this.$route.params.propertyId;
    }
    console.log("this.properties.length", this.properties.length);
    if (this.properties.length === 1) {
      this.reservation.propertyId = this.properties[0].id;
    }
  }
};
</script>

<style lang="scss" scoped></style>
