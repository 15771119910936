<template>
  <div>
    <div class="card">
      <div class="card-header is-block">
        <div class="columns">
          <div class="column">
            <div class="card-header-title is-size-4">
              Reservation
            </div>
          </div>
          <div class="column has-text-right mr-4">
            {{ property.houseNumber }} {{ property.streetName }}<br />
            {{ reservation.dateArrivalUnix | moment("ddd, MMM Do YYYY") }} to
            {{ reservation.dateDepartureUnix | moment("ddd, MMM Do YYYY") }}
          </div>
        </div>
      </div>
      <div class="card-content">
        <div>
          Status: <span class="is-capitalized	">{{ reservation.status }}</span>
        </div>
        <h4 v-if="property">
          Address: {{ property.houseNumber }}
          {{ property.streetName }}
        </h4>
        <h5 v-if="reservation.dateArrivalUnix">
          Arrives:
          {{
            reservation.dateArrivalUnix | moment("dddd, MMMM Do YYYY h:mm a")
          }}
        </h5>
        <h5 v-if="reservation.dateDepartureUnix">
          Departs:
          {{
            reservation.dateDepartureUnix | moment("dddd, MMMM Do YYYY h:mm a")
          }}
        </h5>
      </div>
      <div class="card-footer">
        <router-link
          class="card-footer-item"
          :to="{ name: 'EditReservation', params: { id: reservation.id } }"
          >Edit</router-link
        >
        <a @click="cancelReservation" class="card-footer-item">Cancel</a>
      </div>
    </div>
    <div class="mt-5">
      <div>
        <h4 class="is-size-5 mb-3">
          Guests

          <span v-if="reservation && property">
            <router-link
              class="button is-small"
              :to="{
                name: 'AddGuest',
                params: {
                  reservationId: reservation.id,
                  propertyId: property.id
                }
              }"
              >Add<i class="ml-1 far fa-plus"></i>
            </router-link>
          </span>
        </h4>

        <div class="columns" v-if="guests.length > 0">
          <div class="column is-4" v-for="guest in guests" :key="guest.id">
            <guest-card :guest="guest" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import guestCard from "@/components/guest/card.vue";
import { mapState, mapActions } from "vuex";
export default {
  components: { guestCard },
  computed: {
    ...mapState({
      reservation: (state) => state.reservation.item,
      guests: (state) => state.guest.collection,
      property: (state) => state.property.item
    })
  },
  methods: {
    ...mapActions({
      getReservation: "reservation/getItem",
      getGuests: "guest/getCollection",
      getProperty: "property/getItem"
    }),
    cancelReservation() {}
  },
  async mounted() {
    await this.getReservation(this.$route.params.id);
    await this.getGuests(this.$route.params.id);
    await this.getProperty(this.reservation.propertyId);
  }
};
</script>

<style lang="scss" scoped></style>
